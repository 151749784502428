import { Injectable } from "@angular/core";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { DatePipe } from "@angular/common";


@Injectable({
  providedIn: "root",
})
export class ExportExcelService {
  // Redmeption


  constructor(private datePipe: DatePipe) { }

  pipe = new DatePipe("en-US");

  commonPrintHeaders(headers, sheetName) {
    const finalHeaders = headers;
    // const states = state.map((i) => i.state_name.trim()).join(",");
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetName, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = headers
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });




    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        sheetName + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }




  excelDataValidationSplit(item) {
    let data = item.split(",").join(",");
    data = '"' + data + '"';
    return [`${data}`];
  }

  // Common export
  // first param -> headers -> ['header 1', 'header 2']
  // second param - > name of the file
  // third param -> array of objects that
  // [{name: 'name', key: 'key that needs to be printed', column: 'F', dropDown: array of objects  }]
  exportBrandExcel(headers, title, dropDown) {
    const finalHeaders = headers;
    let categoryHeader = ['Category'];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Add New Details", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      // cell.protection = { locked: true };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        title + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }


  // *************** Common download with body and optional dropdown usage ****************
  /**
   * @auth sujay
   */

  // This function is used to download excel with header and data.
  // this can also be used to add dropdowns
  // data format for without dropdowns is as follows
  // excelData: {
    // headers: ['header1', 'header 2', '...'],
    // column: [{key: 'first_name'},{key: 'second_name'},{key: 'other_coloumn_keys'}], //this should be the key names that we want to print from body 
    // body: [{data}, {data}],
    // hasDropdown: true/false
    // file

    
    // if hasDropdown is true, we need to pass a dropdowns array as well
    // dropdown.name: 'eg: Regions'
    // dropdown.key: 'eg: region_name'
    // dropdown.column: 'D' //column in excel file
    // dropdown.body: [{data}, {data}]
  // }

  commonDownloadWithData(excelData) {
    const finalHeaders = excelData.headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excelData.name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });



    worksheet.columns = excelData.column
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    if (excelData.body.length > 0) {
      excelData.body.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 8,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });
    }

    // only if hasDropdown param is true this code is executed
    if (excelData.hasDropdown == true) {
      excelData.dropDowns.forEach(dropdown => {

        let commonWorksheet = workbook.addWorksheet(dropdown.name, {
          pageSetup: {
            horizontalCentered: true,
            verticalCentered: true,
            paperSize: 9,
            orientation: "portrait",
            margins: {
              left: 0.3149606,
              right: 0.3149606,
              top: 0.3543307,
              bottom: 0.3543307,
              header: 0.3149606,
              footer: 0.3149606,
            },
          },
        });

        //Add Header Row
        let commonHeaderRow = commonWorksheet.addRow(dropdown.name);

        // Cell Style : Fill and Border
        commonHeaderRow.eachCell((cell, number) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "9999FF" },
            bgColor: { argb: "FF0000FF" },
          };
          cell.font = {
            bold: true,
            name: "Calibri",
            size: 8,
          };
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        commonWorksheet.columns = [{ header: dropdown.name, key: dropdown.key }];

        dropdown.body.forEach((d) => {
          let row = commonWorksheet.addRow(d);
          (row.font = {
            name: "Calibri",
            size: 8,
          }),
            (row.alignment = {
              vertical: "middle",
              horizontal: "center",
            });
        });

        commonWorksheet.columns.forEach(function (column, i) {
          column.width = 26;
        });


        let Formulae = [`${dropdown.name}!A2:A${dropdown.body.length + 1}`];

        for (var i = 2; i < 50; i++) {
          worksheet.getCell(`${dropdown.column}${i}`).dataValidation = {
            type: "list",
            allowBlank: false,
            formulae: Formulae,
            errorStyle: "error",
            errorTitle: "choose a status",
            error: "please select",
            showErrorMessage: true,
          };
        }

      });
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        `${excelData.file}` + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }


}




import { Component } from '@angular/core';
import { ActivatedRoute, ActivationEnd, ActivationStart, NavigationEnd, Router } from '@angular/router';
import { AuthenticationCheckService } from './services/auth/authentication-check.service';
import { ApiConfiguration } from './services/http/api-configuration';
import { ApiService } from './services/http/api.service';
import { UploadZipService } from './services/upload-zip/upload-zip.service';
import { DataService } from './services/data/data.service';
import { DataLayerService } from './services/data-layer/data-layer.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isopenUploadBox = false;
  title = 'hubAdmin';
  uploadExcelDetails:any;
  token: any = String;
  uid: any = String;
  loaderConfig = {
    "bgsColor": "rgba(52,51,51,0.19)",
    "bgsOpacity": 0.5,
    "bgsPosition": "bottom-right",
    "bgsSize": 60,
    "bgsType": "ball-scale-multiple",
    "blur": 5,
    "delay": 0,
    "fastFadeOut": true,
    "fgsColor": "#007bff",
    "fgsPosition": "bottom-right",
    "fgsSize": 60,
    "fgsType": "square-jelly-box",
    "gap": 24,
    "logoPosition": "center-center",
    "logoSize": 120,
    "logoUrl": "",
    "masterLoaderId": "master",
    "overlayBorderRadius": "0",
    "overlayColor": "rgba(40, 40, 40, 0.8)",
    "pbColor": "#007bff",
    "pbDirection": "ltr",
    "pbThickness": 3,
    "hasProgressBar": true,
    "text": "",
    "textColor": "#FFFFFF",
    "textPosition": "center-center",
    "maxTime": -1,
    "minTime": 300
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private router: Router,
    private auth: AuthenticationCheckService,
    private dataService: DataService,
    private _dataLayerService: DataLayerService,
    ) {
      
      this.router.events.subscribe(event => { // subscribe to router events
        if (event instanceof NavigationEnd) 
        {
          this._dataLayerService.logPageView(event.url) //call our dataLayer service's page view method to ping home with the url value.
        }
      });

    this.activatedRoute.queryParams.subscribe(params => {
          
          if(params.token){
            this.token = params.token
            this.uid = params.uid
            localStorage.setItem('auth-token', params.token)
            localStorage.setItem('uid', params.uid)
            this.getUserPoints()
          }
      });
  }

  getUserPoints(){

    this.apiService.get(this.apiConfig.getUserPoints + `?token=${this.token}&user_id=${this.uid}`)
    .subscribe((res:any)=>{
      console.log(res)
      localStorage.setItem('login-details', JSON.stringify(res.data))
      this.updateBlancePoints(res.data.balance)
      this.auth.saveAuth(this.token).then((resonse)=>{
        this.router.navigate(["catalogue/dashboard"]);
      })
      
    },err=>{
      console.log(err, 'asdsad')
    })
  }

  updateBlancePoints(points){
    this.dataService.changePointData(points)
 }
}
